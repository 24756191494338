<template>
  <div class="login">
    <b-container fluid="xxl">
      <b-row class="justify-content-md-center mt-5">
        <b-col lg="4">
          <form @submit.prevent="login">
            <div class="form-group">
              <label for="username">Username</label>
              <input type="text" v-model="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" />
              <div v-show="submitted && !username" class="invalid-feedback">Username is required</div>
            </div>
            <div class="form-group">
              <label htmlFor="password">Password</label>
              <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
              <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
            </div>
            <div class="form-group">
              <button class="btn btn-primary" :disabled="loading">Login</button>
              <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
          </form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import router from '@/router'
  import { mapState } from 'vuex'

  export default {
    name: 'Login',
    data() {
      return {
        username: "",
        password: "",
        submitted: false,
        loading: false,
        returnUrl: "",
        error: ""
      };
    },
    created() {
      this.returnUrl = this.$route.query.returnUrl || "/";
    },
    computed: {
      ...mapState({
        user: state => state.user.user,
      })
    },
    methods: {
      login() {
        this.submitted = true;
        const { username, password } = this;

        // stop here if form is invalid
        if (!(username && password)) {
          return;
        }

        this.loading = true;
        this.$store.dispatch('login', {
          username: username,
          password: password
        }).then(() => {
          router.push(this.returnUrl)
        }).catch(error => {
          if(error.response && error.response.status == 401){
            router.push('/login')
            this.error = 'Wrong username and/or password'
          }
          else {
            this.error = error
          }
          this.loading = false
        })
      }
    }
  }
</script>
